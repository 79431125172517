<template>
    <div class="panel panel-default">
        <div class="panel-heading"></div>
        <div class="row panel-body">
            <div class="date_picker col-md-3 col-sm-4 col-xs-6 form-group">
                <label>Week of</label><DatePicker :input-attributes="{ class: 'form-control'}" v-model="state.optionFields.weekOf.val" />
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                <label># Previous</label><input type=text class='form-control' v-model="state.optionFields.numPrevious.val" >
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                <label>Role</label>
                <select class="form-control" v-model="state.optionFields.role.val">
                    <option value="0">All</option>
                    <option value="1">Company</option>
                    <option value="2">Operations</option>
                    <option value="3">Engineering</option>
                    <option value="4">Sales</option>
                    <option value="5">Shipping</option>
                    <option value="6">IT and Development</option>
                </select>

            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form_grid">
                <label><input type='checkbox' :true-value="1" :false-value=null v-model="state.optionFields.locations.val"> Locations</label>
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form-group"><label>Location</label>
                <select class="form-control" v-model="state.optionFields.cdiLocationId.val">
                    <option value="0">All</option>
                    <option v-for="[id, location] in cache.cdiLocationCache.CDI_LOCATIONS" v-bind:key="id" :value="id">{{ location }}</option>
                </select>
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form_grid">
                <label><input type='checkbox' v-model="state.optionFields.clearCache.val" :true-value="1" :false-value=null> Clear Cache</label>
            </div>
        </div>
    </div>
</template>

<script>
    import { store } from '@/store/Report.store.js'
    import cdiLocationCache from '@/cache/cdiLocation.cache.js';
    import DatePicker from "@/components/utils/DatePicker";
    export default {
        name: "Options",
        components: { DatePicker },
        data() {
            return {
                state: store.state,
                toggleHide: true,
                cache: {
                    cdiLocationCache,
                }
            }
        }
    }
</script>

<style scoped>

</style>