<template>
    <div>
        <h1>Scorecard</h1>
        <form @submit.prevent="runReport">
            <Options />
            <Results @enterKeyPressed="update"/>
        </form>
    </div>
</template>

<script>
    import { store } from '@/store/Report.store.js'
    import Options from './scorecard_opt.vue'
    import Results from "./utils/Results";
    import helpers from "@/store/helpers";

    export default {
        name: "scorecard",
        components: {
            Options,
            Results,
        },
        data() {
            return {
                state: store.state,
                optionFields: {
                    weekOf: {val: new Date(new Date().setDate(new Date().getDate() - new Date().getUTCDay() - 7)).toLocaleDateString(), qsField: '[start_date]'},
                    numPrevious: {val: 3, qsField: '[num_weeks]'},
                    role: {val: '0', qsField: '[filter]'},
                    locations: {val: null, qsField: '[show_locations]'},
                    cdiLocationId: {val: '0', qsField: '[cdi_location_id]'},
                    clearCache: {val: '0', qsField: '[no_cache]'}
                },
                groupByFields: {},
                dataFields: {}
            }
        },
        methods: {
            runReport: function() {
                store.runReport();
            },
            update: function(el) {
                let value = el.value;
                let ID = el.getAttribute('measurable_id');
                let sub_id = el.getAttribute('measurable_sub_id');
                let date = el.getAttribute('measurable_date');
                store.customReport('scorecard', 'cache_measurable', helpers.getQSPairs({
                    measurable_value: {val: value, qsField: '[measurable_value]'},
                    measurable_id : {val: ID, qsField: '[measurable_id]'},
                    measurable_sub_id : {val: sub_id, qsField: '[measurable_sub_id]'},
                    measurable_date : {val: date, qsField: '[measurable_date]'},
                }));
            }
        },
        created() {
            this.$appStore.setTitle(['Scorecard']);
            this.$set(store.state.results, this.$options.name, '');
            store.state.runOnLoad = false;
            store.setReport(this.$options.name, this.optionFields, this.groupByFields, this.dataFields);
        }
    }
</script>

<style scoped>

</style>